

import { CF2Component } from 'javascript/lander/runtime'

export default class InputV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.input = this.element.querySelector('.elInput');
    this.re = /^(([^<>()[\]\.,;:#%\s@"]+(\.[^<>()[\]\.,;:#%\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.input.getAttribute('name') === 'shipping_zip') {
      this.input.setAttribute('maxlength', '10');
    }
    this.addInputListeners()

    if (
      this.input.classList.contains('required1') &&
      this.input.getAttribute('name') === 'phone_number' &&
      !$(this.input).closest('[data-page-element="Checkout/V1"]').length &&
      !$(this.input).closest('[data-page-element="Checkout/V2"]').length &&
      !$(this.input).closest('[data-page-element="Surver/V1').length
    ) {
      this.setupPhoneNumberInput()
    }
  }

  update() {
    if (this.input.value) {
      this.element.classList.add('hasValue');
    } else {
      this.element.classList.remove('hasValue');
    }
  }

  addInputListeners(){
    // Add class 'elInputFocused' when input is focused
    this.input.addEventListener('focus', () => {
      this.element.querySelector('[data-input-status-type]').innerHTML = ''
      this.element.classList.remove("elInputValid")
      this.element.classList.remove("elInputError")
      this.element.classList.remove("elInputWarning")
      this.element.classList.add('elInputFocused');
    })

    // Handle On blur event to validate input and add/remove class - 'hasValue'
    this.input.addEventListener('blur', () => {
      this.element.classList.remove('elInputFocused');
      this.update()
      
      if (this.input.getAttribute('name') === 'phone_number' && this.input.iti) {
        this.input.iti.setNumber(this.input.value)
      }
      if (this.input.classList.contains('required1') && this.element.matches('.elInputError')) {
        if (this.input.value === '') {
          return
        } else {
          if (this.input.getAttribute('name') === 'email') {
            const parsedEmail = $.trim(input.value);
            if (this.re.test(parsedEmail)) {
              this.element.classList.add('elInputValid')
              this.element.classList.remove('elInputError')
            }
          } else {
            this.element.classList.remove('elInputError')
            this.element.classList.add('elInputValid')
          }
        }
      }
    })
  }

  setupPhoneNumberInput() {
    window.intlTelInputGlobals.loadUtils('https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.16/build/js/utils.js')
      .then(() => {
        const iti = window.intlTelInput(this.input, {
        autoPlaceholder: 'aggressive',
        // NOTE: If we use utilsScript the library interally wait for the load event to load the utils
        // utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.16/build/js/utils.js',
        preferredCountries: ['us', 'ca', 'gb', 'ie', 'ai', 'nz'],
        initialCountry: window.cfVisitorData?.country ?? 'us',
        
      })
      this.input.iti = iti
    })
  }



}

window["InputV1"] = InputV1

